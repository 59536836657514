import React from 'react';

import Pageholder from './Pages/Pageholder.tsx'

function App() {


  return (
    <React.Fragment>
      <Pageholder/>
    </React.Fragment>
  );
}


export default App;
